@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap"); */

:root {
  --colorblue: #6afae0;
  --coloryellow: #faeb6a;
  --colororange: #f96e42;
  --colorviolet: #6a70fa;
  --colororg2: #ff9a24;
  --colorred: #ff7171;
  --border-opacity: 0.3;
  /* --transition-time: 0.8s; */
  --dark: #000000;
  --light: #fff;
  --border-color: #000000;
  --border-width: 0px;
  --animation-time: 0.2s;
}

html,
body {
  overscroll-behavior: none;
}

body {
  margin: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "DM Sans", sans-serif;
  /* font-family: "IBM Plex Sans", sans-serif; */
  overflow-x: hidden;
  overscroll-behavior: none;
}

a {
  cursor: pointer;
}

.page_container {
  background-color: #000000;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
  overflow-y: hidden;
}

@media screen and (min-width: 1024px) {
  .body_text {
    color: #fff;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .body_heading {
    color: #fff;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: 0;
  }

  .body_text_link {
    color: #2f64eb;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .body_text {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .body_heading {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: 0;
  }

  .body_text_link {
    color: #2f64eb;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 640px) and (max-width: 767px) {
  .body_text {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .body_heading {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: 0;
  }

  .body_text_link {
    color: #2f64eb;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
  }
}

@media screen and (max-width: 639px) {
  .body_text {
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .body_heading {
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: 0;
  }

  .body_text_link {
    color: #2f64eb;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}
